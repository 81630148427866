<template>
    <div
            v-if="$store.getters['sequences_store/getActiveStepId'] === 'injector_general_info_toggleable'&& $store.getters['sequences_store/getActiveStep'].state.value.startsWith('head_zoom')"
            style="
                position: absolute;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
                overflow: hidden;
                background-color: white;
                z-index: 5;

                display:flex;
                padding-left: 25%;
                padding-bottom: 50px;
            "
            @click.stop
    >
        <div style="height: 70%; align-self: end">
            <svg class="enlarged-panel__svg"
                 width="100%"
                 height="100%"
                 viewBox="0 0 95.657089 227.46567"
                 version="1.1"
                 id="svg76651"
            >
                <defs
                        id="defs76648">
                    <clipPath
                            clipPathUnits="userSpaceOnUse"
                            id="clipPath296876-81-1-7">
                        <path
                                d="M 0,4180.346 H 4618.213 V 0 H 0 Z"
                                id="path296874-2-5-9"/>
                    </clipPath>
                    <clipPath
                            clipPathUnits="userSpaceOnUse"
                            id="clipPath297154-34-5-8">
                        <path
                                d="M 0,4180.346 H 4618.213 V 0 H 0 Z"
                                id="path297152-2-4-6"/>
                    </clipPath>
                </defs>
                <g
                        inkscape:label="Слой 1"
                        inkscape:groupmode="layer"
                        id="layer1"
                        transform="translate(-67.419023,-0.6886)">
                    <g
                            id="g296802-9"
                            transform="matrix(0.24834837,0,0,-0.21529891,161.90316,0.6886)"
                            style="stroke-width:1.52562">
                        <path
                                d="m 0,0 h -187.864 -187.863 c -2.609,0 -4.723,-2.115 -4.723,-4.723 V -1056.511 H -187.864 4.723 V -4.723 C 4.723,-2.115 2.608,0 0,0"
                                style="fill:#c1c1c6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.52562"
                                id="path296804-1"/>
                    </g>

                    <g>

                        <path
                                d="M 157.54067,36.2144 H 72.95446 V 8.6907 h 84.58621 z"
                                style="fill:#c1c1c6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352776"
                                id="path296810-4"/>
                        <path
                                d="M 155.8077,34.6979 H 74.68768 V 10.2072 h 81.12002 z"
                                style="fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352776"
                                id="path296812-8"/>
                        <g
                                id="g296814-5"
                                transform="matrix(0.24834837,0,0,-0.25275629,155.80759,10.2072)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 6.979,6 h -340.596 l 6.979,-6 z"
                                    style="fill:#b4b4b9;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path296816-8"/>
                        </g>
                        <g
                                id="g296818-1"
                                transform="matrix(0.24834837,0,0,-0.25275629,155.80759,34.6977)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 6.979,-6 h -340.596 l 6.979,6 z"
                                    style="fill:#b4b4b9;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path296820-9"/>
                        </g>

                        <text
                                style="font-variant:normal;font-weight:600;font-stretch:normal;font-size:3.31354px;font-family:'Proxima Nova Lt';-inkscape-font-specification:ProximaNova-Semibold;writing-mode:lr-tb;fill:#ffffff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352776"
                                id="text297280-8"
                                x="110.75483"
                                y="21.584167"
                                transform="scale(0.99124197,1.0088354)">
                            <tspan
                                    x="110.75483 112.98489 114.88349 115.68204 117.56086 120.32102"
                                    y="21.584167"
                                    sodipodi:role="line"
                                    id="tspan297276-5"
                                    style="stroke-width:0.352776">Volume
                            </tspan>
                            <tspan
                                    x="114.68136 117.44152"
                                    y="25.560486"
                                    sodipodi:role="line"
                                    id="tspan297278-9"
                                    style="stroke-width:0.352776">ml
                            </tspan>
                        </text>
                        <g
                                id="g65914-2"
                                style="stroke-width:0.420939">
                            <text class="numbers" id="000" fill="#00AEFF" xml:space="preserve" style="white-space: pre"
                                  font-family="Digital Numbers" font-size="18" letter-spacing="0em"><tspan x="75.605" y="30.292">0</tspan></text>
                        </g>
                        <g
                                id="g65928-0"
                                style="stroke-width:0.420939">
                            <text class="numbers--panel" id="000_2" fill="#00B51B" xml:space="preserve"
                                  style="white-space: pre"
                                  font-family="Digital Numbers" font-size="18" letter-spacing="0em"><tspan x="122" y="30.292">0</tspan></text>
                        </g>
                    </g>

                    <g
                            id="g296822-2"
                            transform="matrix(0.24834837,0,0,-0.25275629,115.24749,198.3548)"
                            style="stroke-width:1.40805">
                        <path
                                d="M 0,0 C 0,0 88.388,9.307 156.936,55.007 V 599.404 H -156.936 V 55.007 C -88.388,9.307 0,0 0,0"
                                style="fill:#d9d9dc;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path296824-1"/>
                    </g>
                    <path
                            d="m 76.27264,70.772 h 77.94985 V 46.8517 H 76.27264 Z"
                            style="fill:#848489;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352776"
                            id="path296826-1"/>
                    <g
                            id="g296828-9"
                            transform="matrix(0.24834837,0,0,-0.25275629,103.72999,70.7708)"
                            style="stroke-width:1.40805">
                        <path
                                d="m 0,0 h -110.56 v -449.76 c 35.65,-23.77 76.67,-37.7 107.83,-45.58 v 235.93 L 42.84,-179.32 0,-121.88 Z m -9,-9 v -112.88 -2.987 l 1.786,-2.394 39.322,-52.723 -42.66,-74.975 -1.178,-2.07 v -2.381 -224.209 c -26.177,7.379 -59.763,19.542 -89.83,38.733 V -9 Z"
                                style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path296830-0"/>
                    </g>
                    <g
                            id="g296832-3"
                            transform="matrix(0.24834837,0,0,-0.25275629,126.76502,70.7708)"
                            style="stroke-width:1.40805">
                        <path
                                d="M 0,0 H 110.56 V -449.76 C 74.91,-473.53 33.89,-487.46 2.73,-495.34 v 235.93 l -45.57,80.09 42.84,57.44 z m 9,-9 v -112.88 -2.987 l -1.786,-2.394 -39.322,-52.723 42.66,-74.975 1.178,-2.07 v -2.381 -224.209 c 26.177,7.379 59.763,19.542 89.83,38.733 V -9 Z"
                                style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path296834-0"/>
                    </g>
                    <g
                            id="g296836-0"
                            transform="matrix(0.24834837,0,0,-0.25275629,76.27264,184.4515)"
                            style="stroke-width:1.40805">
                        <path
                                d="m 0,0 v -3.745 c 68.548,-45.7 156.936,-55.006 156.936,-55.006 0,0 88.388,9.306 156.936,55.006 V 0 C 245.324,-45.7 156.936,-55.007 156.936,-55.007 156.936,-55.007 68.548,-45.7 0,0"
                                style="fill:#b4b4b9;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path296838-6"/>
                    </g>
                    <path
                            d="m 76.27264,46.8517 h 77.94985 V 46.0771 H 76.27264 Z"
                            style="fill:#b4b4b9;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352776"
                            id="path296840-0"/>

                    <g>

                        <g class="fill-b--panel">
                            <g
                                    class="fill-b-on--panel"
                                    transform="matrix(0.24834837,0,0,-0.25275629,97.9688,59.4724)"
                                    style="stroke-width:1.40805">
                                <path
                                        d="m 0,0 c 0,-12.448 -17.089,-22.539 -38.17,-22.539 -21.081,0 -38.17,10.091 -38.17,22.539 0,12.448 17.089,22.539 38.17,22.539 C -17.089,22.539 0,12.448 0,0"
                                        style="fill:#00aeff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                        id="path296844-4"/>
                            </g>
                            <g
                                    class="fill-b-off--panel"
                                    transform="matrix(0.24834837,0,0,-0.25275629,97.9688,59.4724)"
                                    style="stroke-width:1.40805">
                                <path
                                        d="m 0,0 c 0,-12.448 -17.089,-22.539 -38.17,-22.539 -21.081,0 -38.17,10.091 -38.17,22.539 0,12.448 17.089,22.539 38.17,22.539 C -17.089,22.539 0,12.448 0,0"
                                        style="fill:#00429A;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                        id="path296844-4"/>
                            </g>
                        </g>
                        <g
                                id="g296846-4"
                                transform="matrix(0.24834837,0,0,-0.25275629,97.96157,59.6423)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.61,-12.129 -17.446,-21.852 -38.141,-21.852 -20.694,0 -37.53,9.722 -38.141,21.851 -0.014,-0.227 -0.029,-0.453 -0.029,-0.681 0,-12.444 17.089,-22.532 38.17,-22.532 21.081,0 38.17,10.088 38.17,22.532 C 0.029,-0.453 0.014,-0.227 0,0"
                                    style="fill:#007bb5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path296848-3"/>
                        </g>
                        <g
                                id="g296850-9"
                                transform="matrix(0.24834837,0,0,-0.25275629,88.48929,53.7754)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 20.839,0 37.766,-9.859 38.152,-22.106 0.004,0.142 0.018,0.282 0.018,0.425 0,12.444 -17.089,22.532 -38.17,22.532 -21.081,0 -38.17,-10.088 -38.17,-22.532 0,-0.143 0.014,-0.283 0.018,-0.425 C -37.766,-9.859 -20.839,0 0,0"
                                    style="fill:#52c8ff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path296852-9"/>
                        </g>
                        <g class="fill-a--panel">
                            <g
                                    class="fill-a-on--panel"
                                    transform="matrix(0.24834837,0,0,-0.25275629,151.48527,59.4504)"
                                    style="stroke-width:1.40805">
                                <path
                                        d="m 0,0 c 0,-12.488 -17.089,-22.612 -38.17,-22.612 -21.081,0 -38.171,10.124 -38.171,22.612 0,12.488 17.09,22.612 38.171,22.612 C -17.089,22.612 0,12.488 0,0"
                                        style="fill:#01ff25;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                        id="path296856-7"/>
                            </g>
                            <g
                                    class="fill-a-off--panel"
                                    transform="matrix(0.24834837,0,0,-0.25275629,151.48527,59.4504)"
                                    style="stroke-width:1.40805">
                                <path
                                        d="m 0,0 c 0,-12.488 -17.089,-22.612 -38.17,-22.612 -21.081,0 -38.171,10.124 -38.171,22.612 0,12.488 17.09,22.612 38.171,22.612 C -17.089,22.612 0,12.488 0,0"
                                        style="fill:#487C23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                        id="path296856-7"/>
                            </g>
                        </g>
                        <g
                                id="g296858-7"
                                transform="matrix(0.24834837,0,0,-0.25275629,151.478,59.6423)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.61,-12.129 -17.446,-21.852 -38.141,-21.852 -20.694,0 -37.53,9.722 -38.141,21.851 -0.014,-0.227 -0.029,-0.453 -0.029,-0.681 0,-12.444 17.089,-22.532 38.17,-22.532 21.081,0 38.17,10.088 38.17,22.532 C 0.029,-0.453 0.014,-0.227 0,0"
                                    style="fill:#00b51b;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path296860-2"/>
                        </g>
                        <g
                                id="g296862-4"
                                transform="matrix(0.24834837,0,0,-0.25275629,142.00572,53.7754)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 20.839,0 37.766,-9.859 38.152,-22.106 0.004,0.142 0.018,0.282 0.018,0.425 0,12.444 -17.089,22.532 -38.17,22.532 -21.081,0 -38.17,-10.088 -38.17,-22.532 0,-0.143 0.014,-0.283 0.018,-0.425 C -37.766,-9.859 -20.839,0 0,0"
                                    style="fill:#52ff6b;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path296864-1"/>
                        </g>
                        <text
                                style="font-variant:normal;font-weight:600;font-stretch:normal;font-size:5.25548px;font-family:'Proxima Nova Lt';-inkscape-font-specification:ProximaNova-Semibold;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352776"
                                id="text296868-7"
                                x="135.94232"
                                y="51.749084"
                                transform="scale(0.99124197,1.0088354)">
                            <tspan
                                    x="135.94232 138.89592 140.2571 142.97412 145.69122 147.04189 82.021111 84.974648 86.335808 89.05291 91.770012 93.120674"
                                    y="51.749084"
                                    sodipodi:role="line"
                                    id="tspan296866-2"
                                    style="stroke-width:0.352776">FILL AFILL B
                            </tspan>
                        </text>
                        <g
                                id="g296870-3"
                                transform="matrix(0.24834837,0,0,-0.25275629,-862.2855,360.48172)"
                                style="stroke-width:1.40805">
                            <g
                                    id="g296872-2"
                                    clip-path="url(#clipPath296876-81-1-7)"
                                    style="stroke-width:1.40805">
                                <g class="plus-b--panel"
                                >
                                    <g
                                            id="g296878-6"
                                            transform="translate(3902.8213,1216.9213)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="m 0,0 c 0,7.123 -5.774,12.898 -12.898,12.898 -7.123,0 -12.897,-5.775 -12.897,-12.898 0,0 -0.206,-10.824 8.652,-22.879 h 4.245 4.245 C 0.205,-10.824 0,0 0,0"
                                                style="fill:#c1c1c6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296880-8"/>
                                    </g>
                                    <g
                                            id="g296882-1"
                                            transform="translate(3902.8101,1217.1404)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="m 0,0 c -0.093,-2.333 -0.951,-11.866 -8.641,-22.332 h -4.246 -4.245 c -7.69,10.466 -8.548,19.999 -8.641,22.332 -0.001,-0.074 -0.011,-0.145 -0.011,-0.219 0,0 -0.206,-10.824 8.652,-22.879 h 4.245 4.246 C 0.216,-11.043 0.011,-0.219 0.011,-0.219 0.011,-0.145 0.001,-0.074 0,0"
                                                style="fill:#929296;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296884-3"/>
                                    </g>
                                    <g
                                            id="g296886-0"
                                            transform="translate(3889.9233,1229.053)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="m 0,0 c 7.049,0 12.769,-5.658 12.887,-12.679 0.014,0.355 0.011,0.547 0.011,0.547 0,7.123 -5.774,12.898 -12.898,12.898 -7.123,0 -12.897,-5.775 -12.897,-12.898 0,0 -0.004,-0.192 0.011,-0.547 C -12.768,-5.658 -7.049,0 0,0"
                                                style="fill:#f0f0f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296888-6"/>
                                    </g>
                                    <g
                                            id="g296904-1"
                                            transform="translate(3895.647,1214.053)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="M 0,0 H -4.277 V 4.277 H -7.17 V 0 h -4.277 V -2.894 H -7.17 V -7.17 h 2.893 v 4.276 H 0 Z"
                                                style="fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296906-3"/>
                                    </g>
                                </g>
                                <g class="minus-b--panel">
                                    <g
                                            id="g296890-2"
                                            transform="translate(3877.0259,1168.9188)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="m 0,0 c 0,-7.123 5.774,-12.898 12.898,-12.898 7.123,0 12.897,5.775 12.897,12.898 0,0 0.205,10.824 -8.652,22.879 H 12.898 8.653 C -0.205,10.824 0,0 0,0"
                                                style="fill:#c1c1c6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296892-5"/>
                                    </g>
                                    <g
                                            id="g296894-8"
                                            transform="translate(3877.0369,1168.6997)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="m 0,0 c 0.093,2.332 0.951,11.866 8.641,22.332 h 4.246 4.245 c 7.69,-10.466 8.548,-20 8.641,-22.332 0.001,0.074 0.011,0.145 0.011,0.219 0,0 0.206,10.824 -8.652,22.879 H 12.887 8.641 C -0.216,11.043 -0.011,0.219 -0.011,0.219 -0.011,0.145 -0.001,0.074 0,0"
                                                style="fill:#f0f0f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296896-9"/>
                                    </g>
                                    <g
                                            id="g296898-4"
                                            transform="translate(3889.9236,1156.787)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="m 0,0 c -7.049,0 -12.769,5.658 -12.887,12.679 -0.014,-0.354 -0.011,-0.547 -0.011,-0.547 0,-7.123 5.774,-12.898 12.898,-12.898 7.123,0 12.897,5.775 12.897,12.898 0,0 0.004,0.193 -0.011,0.547 C 12.768,5.658 7.049,0 0,0"
                                                style="fill:#929296;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296900-4"/>
                                    </g>
                                    <path
                                            d="m 3884.2,1171.593 h 11.447 v -2.894 H 3884.2 Z"
                                            style="fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                            id="path296902-0"/>
                                </g>
                                <g class="plus-a--panel">
                                    <g
                                            id="g296908-5"
                                            transform="translate(3995.2468,1216.9213)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="m 0,0 c 0,7.123 -5.774,12.898 -12.898,12.898 -7.123,0 -12.897,-5.775 -12.897,-12.898 0,0 -0.206,-10.824 8.652,-22.879 h 4.245 4.245 C 0.205,-10.824 0,0 0,0"
                                                style="fill:#c1c1c6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296910-4"/>
                                    </g>
                                    <g
                                            id="g296912-0"
                                            transform="translate(3995.2356,1217.1404)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="m 0,0 c -0.093,-2.333 -0.951,-11.866 -8.641,-22.332 h -4.246 -4.245 c -7.69,10.466 -8.548,19.999 -8.641,22.332 -0.001,-0.074 -0.011,-0.145 -0.011,-0.219 0,0 -0.206,-10.824 8.652,-22.879 h 4.245 4.246 C 0.216,-11.043 0.011,-0.219 0.011,-0.219 0.011,-0.145 0.001,-0.074 0,0"
                                                style="fill:#929296;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296914-4"/>
                                    </g>
                                    <g
                                            id="g296916-6"
                                            transform="translate(3982.3489,1229.053)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="m 0,0 c 7.049,0 12.769,-5.658 12.887,-12.679 0.014,0.355 0.011,0.547 0.011,0.547 0,7.123 -5.774,12.898 -12.898,12.898 -7.123,0 -12.897,-5.775 -12.897,-12.898 0,0 -0.004,-0.192 0.011,-0.547 C -12.768,-5.658 -7.049,0 0,0"
                                                style="fill:#f0f0f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296918-7"/>
                                    </g>
                                    <g
                                            id="g296934-0"
                                            transform="translate(3988.0725,1214.053)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="M 0,0 H -4.277 V 4.277 H -7.17 V 0 h -4.277 V -2.894 H -7.17 V -7.17 h 2.893 v 4.276 H 0 Z"
                                                style="fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296936-1"/>
                                    </g>
                                </g>
                                <g class="minus-a--panel">
                                    <g
                                            id="g296920-9"
                                            transform="translate(3969.4514,1168.9188)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="m 0,0 c 0,-7.123 5.774,-12.898 12.898,-12.898 7.123,0 12.897,5.775 12.897,12.898 0,0 0.205,10.824 -8.652,22.879 H 12.898 8.653 C -0.205,10.824 0,0 0,0"
                                                style="fill:#c1c1c6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296922-7"/>
                                    </g>
                                    <g
                                            id="g296924-6"
                                            transform="translate(3969.4624,1168.6997)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="m 0,0 c 0.093,2.332 0.951,11.866 8.641,22.332 h 4.246 4.245 c 7.69,-10.466 8.548,-20 8.641,-22.332 0.001,0.074 0.011,0.145 0.011,0.219 0,0 0.206,10.824 -8.652,22.879 H 12.887 8.641 C -0.216,11.043 -0.011,0.219 -0.011,0.219 -0.011,0.145 -0.001,0.074 0,0"
                                                style="fill:#f0f0f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296926-7"/>
                                    </g>
                                    <g
                                            id="g296928-0"
                                            transform="translate(3982.3491,1156.787)"
                                            style="stroke-width:1.40805">
                                        <path
                                                d="m 0,0 c -7.049,0 -12.769,5.658 -12.887,12.679 -0.014,-0.354 -0.011,-0.547 -0.011,-0.547 0,-7.123 5.774,-12.898 12.898,-12.898 7.123,0 12.897,5.775 12.897,12.898 0,0 0.004,0.193 -0.011,0.547 C 12.768,5.658 7.049,0 0,0"
                                                style="fill:#929296;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                                id="path296930-2"/>
                                    </g>
                                    <path
                                            d="m 3976.626,1171.593 h 11.447 v -2.894 h -11.447 z"
                                            style="fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                            id="path296932-0"/>
                                </g>
                            </g>
                        </g>
                        <text
                                style="font-variant:normal;font-weight:600;font-stretch:normal;font-size:2.89859px;font-family:'Proxima Nova Lt';-inkscape-font-specification:ProximaNova-Semibold;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352776"
                                id="text296940-0"
                                x="109.60499"
                                y="51.097961"
                                transform="scale(0.99124197,1.0088354)">
                            <tspan
                                    x="109.60499 111.55573 113.19927 114.12392 115.78478 116.52972 118.02825 119.68913 121.24277"
                                    y="51.097961"
                                    sodipodi:role="line"
                                    id="tspan296938-3"
                                    style="stroke-width:0.352776">Auto Load
                            </tspan>
                        </text>
                        <g class="autoload--panel"

                        >
                            <g
                                    transform="matrix(0.24834837,0,0,-0.25275629,121.16746,59.0605)"
                                    style="stroke-width:1.40805">
                                <path
                                        d="m 0,0 c 0,-13.165 -10.672,-23.837 -23.837,-23.837 -13.165,0 -23.838,10.672 -23.838,23.837 0,13.165 10.673,23.837 23.838,23.837 C -10.672,23.837 0,13.165 0,0"
                                        style="fill:#1b3978;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                        id="path296952-0"/>
                            </g>
                            <g
                                    id="g296954-0"
                                    transform="matrix(0.24834837,0,0,-0.25275629,118.80024,59.0605)"
                                    style="stroke-width:1.40805">
                                <path
                                        d="m 0,0 c 0,-7.901 -6.405,-14.305 -14.305,-14.305 -7.901,0 -14.306,6.404 -14.306,14.305 0,7.901 6.405,14.305 14.306,14.305 C -6.405,14.305 0,7.901 0,0"
                                        style="fill:#c1c1c6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                        id="path296956-2"/>
                            </g>
                            <g
                                    id="g296958-6"
                                    transform="matrix(0.24834837,0,0,-0.25275629,115.24749,55.5939)"
                                    style="stroke-width:1.40805">
                                <path
                                        d="m 0,0 c 7.801,0 14.132,-6.247 14.291,-14.01 10e-4,0.099 0.014,0.195 0.014,0.295 C 14.305,-5.814 7.901,0.59 0,0.59 c -7.901,0 -14.305,-6.404 -14.305,-14.305 0,-0.1 0.013,-0.196 0.015,-0.295 C -14.132,-6.247 -7.801,0 0,0"
                                        style="fill:#f0f0f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                        id="path296960-8"/>
                            </g>
                            <g
                                    id="g296962-8"
                                    transform="matrix(0.24834837,0,0,-0.25275629,115.24749,62.5831)"
                                    style="stroke-width:1.40805">
                                <path
                                        d="m 0,0 c -7.838,0 -14.196,6.306 -14.296,14.121 -0.001,-0.062 -0.009,-0.122 -0.009,-0.184 0,-7.901 6.404,-14.306 14.305,-14.306 7.901,0 14.305,6.405 14.305,14.306 0,0.062 -0.008,0.122 -0.009,0.184 C 14.197,6.306 7.838,0 0,0"
                                        style="fill:#929296;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                        id="path296964-6"/>
                            </g>
                            <g
                                    id="g296966-7"
                                    transform="matrix(0.24834837,0,0,-0.25275629,115.24749,64.8874)"
                                    style="stroke-width:1.40805">
                                <path
                                        d="m 0,0 c -13.033,0 -23.617,10.462 -23.827,23.445 -0.003,-0.131 -0.01,-0.26 -0.01,-0.392 0,-13.165 10.672,-23.837 23.837,-23.837 13.165,0 23.837,10.672 23.837,23.837 0,0.132 -0.007,0.261 -0.01,0.392 C 23.617,10.462 13.033,0 0,0"
                                        style="fill:#142957;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                        id="path296968-0"/>
                            </g>
                            <g
                                    id="g296970-7"
                                    transform="matrix(0.24834837,0,0,-0.25275629,115.24749,53.1941)"
                                    style="stroke-width:1.40805">
                                <path
                                        d="m 0,0 c 13.06,0 23.661,-10.504 23.829,-23.524 0.002,0.105 0.008,0.209 0.008,0.314 0,13.165 -10.672,23.837 -23.837,23.837 -13.165,0 -23.837,-10.672 -23.837,-23.837 0,-0.105 0.006,-0.209 0.008,-0.314 C -23.661,-10.504 -13.06,0 0,0"
                                        style="fill:#244ca1;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                        id="path296972-1"/>
                            </g>
                        </g>
                    </g>

                    <g class="manual_controls_b_decrease--panel"
                    >
                        <rect x='79' y="84" width="20.64" height="44.76" fill="transparent"/>
                        <g
                                id="g296976-1"
                                transform="matrix(0.24834837,0,0,-0.25275629,96.40632,89.5243)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.386,0 -0.776,0.111 -1.12,0.344 l -23.6,15.984 -23.6,-15.984 c -0.916,-0.619 -2.159,-0.379 -2.778,0.534 -0.619,0.915 -0.38,2.159 0.535,2.778 l 24.722,16.743 c 0.677,0.459 1.565,0.459 2.243,0 L 1.124,3.656 C 2.038,3.037 2.277,1.793 1.658,0.878 1.271,0.308 0.641,0 0,0"
                                    style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path296978-8"/>
                        </g>
                        <g
                                id="g296980-7"
                                transform="matrix(0.24834837,0,0,-0.25275629,94.62847,97.5042)"
                                style="stroke-width:1.40805">
                            <path
                                    d="M 0,0 C -0.386,0 -0.776,0.111 -1.12,0.344 L -17.561,11.479 -34.003,0.344 c -0.915,-0.62 -2.159,-0.38 -2.777,0.534 -0.62,0.915 -0.381,2.159 0.534,2.778 l 17.563,11.895 c 0.678,0.459 1.566,0.459 2.244,0 L 1.124,3.656 C 2.038,3.037 2.277,1.793 1.658,0.878 1.271,0.308 0.641,0 0,0"
                                    style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path296982-4"/>
                        </g>
                        <g
                                id="g296984-1"
                                transform="matrix(0.24834837,0,0,-0.25275629,85.90582,105.4842)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.642,0 -1.271,0.308 -1.658,0.878 -0.619,0.915 -0.38,2.159 0.534,2.778 L 16.44,15.551 c 0.678,0.459 1.565,0.459 2.243,0 L 36.246,3.656 C 37.161,3.037 37.4,1.793 36.78,0.878 36.161,-0.036 34.917,-0.276 34.003,0.344 L 17.562,11.479 1.12,0.344 C 0.776,0.111 0.386,0 0,0"
                                    style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path296986-2"/>
                        </g>
                        <g
                                id="g296988-6"
                                transform="matrix(0.24834837,0,0,-0.25275629,93.88705,112.9531)"
                                style="stroke-width:1.40805">
                            <path
                                    d="M 0,0 C -0.386,0 -0.776,0.111 -1.12,0.344 L -14.576,9.458 -28.032,0.344 c -0.916,-0.619 -2.159,-0.38 -2.778,0.535 -0.619,0.914 -0.38,2.157 0.535,2.777 l 14.578,9.873 c 0.677,0.459 1.565,0.459 2.243,0 L 1.124,3.656 C 2.038,3.036 2.277,1.793 1.658,0.879 1.271,0.308 0.641,0 0,0"
                                    style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path296990-5"/>
                        </g>
                        <g
                                id="g296992-7"
                                transform="matrix(0.24834837,0,0,-0.25275629,87.2168,119.9028)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.481,0 -0.954,0.23 -1.244,0.659 -0.464,0.686 -0.284,1.618 0.401,2.083 l 12.284,8.32 c 0.509,0.343 1.174,0.343 1.683,0 L 25.408,2.742 C 26.093,2.277 26.273,1.345 25.809,0.659 25.344,-0.028 24.411,-0.208 23.725,0.258 L 12.283,8.008 0.84,0.258 C 0.582,0.083 0.29,0 0,0"
                                    style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path296994-5"/>
                        </g>
                        <g
                                id="g296996-7"
                                transform="matrix(0.24834837,0,0,-0.25275629,87.21669,126.8527)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.321,0 -0.636,0.153 -0.829,0.439 -0.31,0.457 -0.19,1.08 0.267,1.389 l 12.285,8.319 c 0.338,0.229 0.783,0.229 1.121,0 L 25.127,1.828 C 25.585,1.519 25.705,0.896 25.395,0.439 25.085,-0.019 24.463,-0.138 24.006,0.172 L 12.283,8.111 0.56,0.172 C 0.388,0.056 0.193,0 0,0"
                                    style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path296998-1"/>
                        </g>
                    </g>
                    <g class="manual_controls_b_increase--panel"
                    >
                        <rect x='79' y="135" width="20.64" height="44.76" fill="transparent"/>
                        <g
                                id="g297000-9"
                                transform="matrix(0.24834837,0,0,-0.25275629,90.2672,178.8386)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.392,0 -0.783,0.114 -1.122,0.344 l -24.722,16.743 c -0.914,0.62 -1.154,1.863 -0.534,2.777 0.618,0.915 1.862,1.155 2.777,0.535 L 0,4.415 23.6,20.399 c 0.916,0.619 2.158,0.38 2.777,-0.535 0.62,-0.914 0.381,-2.157 -0.534,-2.777 L 1.122,0.344 C 0.783,0.114 0.392,0 0,0"
                                    style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297002-5"/>
                        </g>
                        <g
                                id="g297004-2"
                                transform="matrix(0.24834837,0,0,-0.25275629,90.2672,169.6334)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.392,0 -0.783,0.114 -1.122,0.344 l -17.563,11.895 c -0.915,0.62 -1.154,1.864 -0.534,2.778 0.618,0.916 1.862,1.154 2.777,0.535 L 0,4.416 16.441,15.552 c 0.916,0.619 2.158,0.379 2.778,-0.535 0.619,-0.914 0.38,-2.158 -0.534,-2.778 L 1.122,0.344 C 0.783,0.114 0.392,0 0,0"
                                    style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297006-4"/>
                        </g>
                        <g
                                id="g297008-5"
                                transform="matrix(0.24834837,0,0,-0.25275629,90.2672,161.6533)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.392,0 -0.783,0.114 -1.122,0.344 l -17.563,11.894 c -0.915,0.62 -1.154,1.864 -0.534,2.778 0.618,0.915 1.862,1.154 2.777,0.535 L 0,4.415 16.441,15.551 c 0.916,0.618 2.159,0.38 2.778,-0.535 0.619,-0.914 0.38,-2.158 -0.534,-2.778 L 1.122,0.344 C 0.783,0.114 0.392,0 0,0"
                                    style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297010-1"/>
                        </g>
                        <g
                                id="g297012-9"
                                transform="matrix(0.24834837,0,0,-0.25275629,90.2672,153.6735)"
                                style="stroke-width:1.40805">
                            <path
                                    d="M 0,0 C -0.392,0 -0.783,0.114 -1.122,0.344 L -15.7,10.217 c -0.914,0.62 -1.154,1.863 -0.534,2.777 0.618,0.915 1.862,1.155 2.777,0.535 L 0,4.415 13.456,13.529 c 0.916,0.618 2.158,0.38 2.777,-0.535 0.62,-0.914 0.381,-2.157 -0.534,-2.777 L 1.122,0.344 C 0.783,0.114 0.392,0 0,0"
                                    style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297014-4"/>
                        </g>
                        <g
                                id="g297016-5"
                                transform="matrix(0.24834837,0,0,-0.25275629,90.2672,146.0782)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.293,0 -0.587,0.086 -0.841,0.258 l -12.284,8.319 c -0.686,0.465 -0.866,1.398 -0.401,2.083 0.464,0.688 1.397,0.866 2.083,0.402 L 0,3.312 l 11.442,7.75 c 0.686,0.464 1.62,0.286 2.084,-0.402 C 13.99,9.975 13.811,9.042 13.125,8.577 L 0.841,0.258 C 0.587,0.086 0.293,0 0,0"
                                    style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297018-3"/>
                        </g>
                        <g
                                id="g297020-8"
                                transform="matrix(0.24834837,0,0,-0.25275629,90.2672,138.8756)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.196,0 -0.392,0.058 -0.561,0.172 l -12.284,8.319 c -0.457,0.31 -0.577,0.932 -0.267,1.389 0.309,0.456 0.931,0.577 1.388,0.267 L 0,2.208 11.723,10.147 c 0.457,0.31 1.079,0.191 1.389,-0.267 0.309,-0.457 0.19,-1.079 -0.268,-1.389 L 0.561,0.172 C 0.392,0.058 0.196,0 0,0"
                                    style="fill:#459182;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297022-9"/>
                        </g>
                    </g>
                    <g class="manual_controls_a_decrease--panel"
                    >
                        <rect x='131' y="84" width="20.64" height="44.76" fill="transparent"/>
                        <g
                                id="g297024-9"
                                transform="matrix(0.24834837,0,0,-0.25275629,147.52726,89.5243)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.386,0 -0.776,0.111 -1.12,0.344 l -23.6,15.984 -23.6,-15.984 c -0.916,-0.619 -2.159,-0.379 -2.778,0.534 -0.619,0.915 -0.38,2.159 0.535,2.778 l 24.722,16.743 c 0.677,0.459 1.565,0.459 2.243,0 L 1.124,3.656 C 2.038,3.037 2.277,1.793 1.658,0.878 1.271,0.308 0.641,0 0,0"
                                    style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297026-7"/>
                        </g>
                        <g
                                id="g297028-0"
                                transform="matrix(0.24834837,0,0,-0.25275629,145.74941,97.5042)"
                                style="stroke-width:1.40805">
                            <path
                                    d="M 0,0 C -0.386,0 -0.776,0.111 -1.12,0.344 L -17.561,11.479 -34.003,0.344 c -0.915,-0.62 -2.159,-0.38 -2.777,0.534 -0.62,0.915 -0.381,2.159 0.534,2.778 l 17.563,11.895 c 0.678,0.459 1.566,0.459 2.244,0 L 1.124,3.656 C 2.038,3.037 2.277,1.793 1.658,0.878 1.271,0.308 0.641,0 0,0"
                                    style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297030-9"/>
                        </g>
                        <g
                                id="g297032-6"
                                transform="matrix(0.24834837,0,0,-0.25275629,137.0268,105.4842)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.642,0 -1.271,0.308 -1.658,0.878 -0.619,0.915 -0.38,2.159 0.534,2.778 L 16.44,15.551 c 0.678,0.459 1.565,0.459 2.243,0 L 36.246,3.656 C 37.161,3.037 37.4,1.793 36.78,0.878 36.161,-0.036 34.917,-0.276 34.003,0.344 L 17.562,11.479 1.12,0.344 C 0.776,0.111 0.386,0 0,0"
                                    style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297034-6"/>
                        </g>
                        <g
                                id="g297036-3"
                                transform="matrix(0.24834837,0,0,-0.25275629,145.00802,112.9531)"
                                style="stroke-width:1.40805">
                            <path
                                    d="M 0,0 C -0.386,0 -0.776,0.111 -1.12,0.344 L -14.576,9.458 -28.032,0.344 c -0.916,-0.619 -2.159,-0.38 -2.778,0.535 -0.619,0.914 -0.38,2.157 0.535,2.777 l 14.578,9.873 c 0.677,0.459 1.565,0.459 2.243,0 L 1.124,3.656 C 2.038,3.036 2.277,1.793 1.658,0.879 1.271,0.308 0.641,0 0,0"
                                    style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297038-2"/>
                        </g>
                        <g
                                id="g297040-3"
                                transform="matrix(0.24834837,0,0,-0.25275629,138.33778,119.9028)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.481,0 -0.954,0.23 -1.244,0.659 -0.464,0.686 -0.284,1.618 0.401,2.083 l 12.284,8.32 c 0.509,0.343 1.174,0.343 1.683,0 L 25.408,2.742 C 26.093,2.277 26.273,1.345 25.809,0.659 25.344,-0.028 24.411,-0.208 23.725,0.258 L 12.283,8.008 0.84,0.258 C 0.582,0.083 0.29,0 0,0"
                                    style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297042-5"/>
                        </g>
                        <g
                                id="g297044-9"
                                transform="matrix(0.24834837,0,0,-0.25275629,138.33765,126.8527)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.321,0 -0.636,0.153 -0.829,0.439 -0.31,0.457 -0.19,1.08 0.267,1.389 l 12.285,8.319 c 0.338,0.229 0.783,0.229 1.121,0 L 25.127,1.828 C 25.585,1.519 25.705,0.896 25.395,0.439 25.085,-0.019 24.463,-0.138 24.006,0.172 L 12.283,8.111 0.56,0.172 C 0.388,0.056 0.193,0 0,0"
                                    style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297046-1"/>
                        </g>
                    </g>
                    <g class="manual_controls_a_increase--panel"
                    >
                        <rect x='131' y="135" width="20.64" height="44.76" fill="transparent"/>
                        <g
                                id="g297048-7"
                                transform="matrix(0.24834837,0,0,-0.25275629,141.38817,178.8386)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.392,0 -0.783,0.114 -1.122,0.344 l -24.722,16.743 c -0.914,0.62 -1.154,1.863 -0.534,2.777 0.618,0.915 1.862,1.155 2.777,0.535 L 0,4.415 23.6,20.399 c 0.916,0.619 2.158,0.38 2.777,-0.535 0.62,-0.914 0.381,-2.157 -0.534,-2.777 L 1.122,0.344 C 0.783,0.114 0.392,0 0,0"
                                    style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297050-7"/>
                        </g>
                        <g
                                id="g297052-7"
                                transform="matrix(0.24834837,0,0,-0.25275629,141.38817,169.6334)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.392,0 -0.783,0.114 -1.122,0.344 l -17.563,11.895 c -0.915,0.62 -1.154,1.864 -0.534,2.778 0.618,0.916 1.862,1.154 2.777,0.535 L 0,4.416 16.441,15.552 c 0.916,0.619 2.158,0.379 2.778,-0.535 0.619,-0.914 0.38,-2.158 -0.534,-2.778 L 1.122,0.344 C 0.783,0.114 0.392,0 0,0"
                                    style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297054-5"/>
                        </g>
                        <g
                                id="g297056-3"
                                transform="matrix(0.24834837,0,0,-0.25275629,141.38817,161.6533)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.392,0 -0.783,0.114 -1.122,0.344 l -17.563,11.894 c -0.915,0.62 -1.154,1.864 -0.534,2.778 0.618,0.915 1.862,1.154 2.777,0.535 L 0,4.415 16.441,15.551 c 0.916,0.618 2.159,0.38 2.778,-0.535 0.619,-0.914 0.38,-2.158 -0.534,-2.778 L 1.122,0.344 C 0.783,0.114 0.392,0 0,0"
                                    style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297058-8"/>
                        </g>
                        <g
                                id="g297060-0"
                                transform="matrix(0.24834837,0,0,-0.25275629,141.38817,153.6735)"
                                style="stroke-width:1.40805">
                            <path
                                    d="M 0,0 C -0.392,0 -0.783,0.114 -1.122,0.344 L -15.7,10.217 c -0.914,0.62 -1.154,1.863 -0.534,2.777 0.618,0.915 1.862,1.155 2.777,0.535 L 0,4.415 13.456,13.529 c 0.916,0.618 2.158,0.38 2.777,-0.535 0.62,-0.914 0.381,-2.157 -0.534,-2.777 L 1.122,0.344 C 0.783,0.114 0.392,0 0,0"
                                    style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297062-3"/>
                        </g>
                        <g
                                id="g297064-3"
                                transform="matrix(0.24834837,0,0,-0.25275629,141.38817,146.0782)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.293,0 -0.587,0.086 -0.841,0.258 l -12.284,8.319 c -0.686,0.465 -0.866,1.398 -0.401,2.083 0.464,0.688 1.397,0.866 2.083,0.402 L 0,3.312 l 11.442,7.75 c 0.686,0.464 1.62,0.286 2.084,-0.402 C 13.99,9.975 13.811,9.042 13.125,8.577 L 0.841,0.258 C 0.587,0.086 0.293,0 0,0"
                                    style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297066-4"/>
                        </g>
                        <g
                                id="g297068-3"
                                transform="matrix(0.24834837,0,0,-0.25275629,141.38817,138.8756)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -0.196,0 -0.392,0.058 -0.561,0.172 l -12.284,8.319 c -0.457,0.31 -0.577,0.932 -0.267,1.389 0.309,0.456 0.931,0.577 1.388,0.267 L 0,2.208 11.723,10.147 c 0.457,0.31 1.079,0.191 1.389,-0.267 0.309,-0.457 0.19,-1.079 -0.268,-1.389 L 0.561,0.172 C 0.392,0.058 0.196,0 0,0"
                                    style="fill:#487c23;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297070-0"/>
                        </g>
                    </g>
                    <g class="arm-button--panel">
                        <g
                                class="arm-on--panel"
                                transform="matrix(0.24834837,0,0,-0.25275629,121.67229,185.0311)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 0,-14.288 -11.583,-25.87 -25.87,-25.87 -14.287,0 -25.87,11.582 -25.87,25.87 0,14.288 11.583,25.87 25.87,25.87 C -11.583,25.87 0,14.288 0,0"
                                    style="fill:#00aeff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297074-5"/>
                        </g>
                        <g
                                class="arm-off--panel"
                                transform="matrix(0.24834837,0,0,-0.25275629,121.67229,185.0311)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 0,-14.288 -11.583,-25.87 -25.87,-25.87 -14.287,0 -25.87,11.582 -25.87,25.87 0,14.288 11.583,25.87 25.87,25.87 C -11.583,25.87 0,14.288 0,0"
                                    style="fill:#1b3978;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297074-5"/>
                        </g>
                        <g
                                id="g297076-8"
                                transform="matrix(0.24834837,0,0,-0.25275629,119.10315,185.0311)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 0,-8.574 -6.951,-15.525 -15.525,-15.525 -8.574,0 -15.525,6.951 -15.525,15.525 0,8.574 6.951,15.525 15.525,15.525 C -6.951,15.525 0,8.574 0,0"
                                    style="fill:#c1c1c6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297078-8"/>
                        </g>
                        <g
                                id="g297080-0"
                                transform="matrix(0.24834837,0,0,-0.25275629,115.24749,181.2689)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 8.466,0 15.337,-6.78 15.509,-15.205 0.002,0.108 0.016,0.212 0.016,0.32 0,8.575 -6.951,15.526 -15.525,15.526 -8.574,0 -15.525,-6.951 -15.525,-15.526 0,-0.108 0.014,-0.212 0.016,-0.32 C -15.337,-6.78 -8.466,0 0,0"
                                    style="fill:#f0f0f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297082-4"/>
                        </g>
                        <g
                                id="g297084-6"
                                transform="matrix(0.24834837,0,0,-0.25275629,115.24749,188.854)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -8.507,0 -15.406,6.844 -15.515,15.325 -10e-4,-0.067 -0.01,-0.132 -0.01,-0.2 C -15.525,6.551 -8.574,-0.4 0,-0.4 c 8.574,0 15.525,6.951 15.525,15.525 0,0.068 -0.009,0.133 -0.01,0.2 C 15.407,6.844 8.507,0 0,0"
                                    style="fill:#929296;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297086-4"/>
                        </g>
                        <g
                                id="g297088-3"
                                transform="matrix(0.24834837,0,0,-0.25275629,115.24749,191.3547)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -14.145,0 -25.631,11.354 -25.859,25.444 -0.002,-0.142 -0.011,-0.283 -0.011,-0.425 0,-14.288 11.583,-25.87 25.87,-25.87 14.288,0 25.87,11.582 25.87,25.87 0,0.142 -0.008,0.283 -0.011,0.425 C 25.631,11.354 14.145,0 0,0"
                                    style="fill:#142957;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297090-8"/>
                        </g>
                        <g
                                id="g297092-3"
                                transform="matrix(0.24834837,0,0,-0.25275629,115.24749,178.6643)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 14.173,0 25.678,-11.4 25.861,-25.529 0.002,0.113 0.009,0.226 0.009,0.34 0,14.288 -11.582,25.87 -25.87,25.87 -14.287,0 -25.87,-11.582 -25.87,-25.87 0,-0.114 0.007,-0.227 0.009,-0.34 C -25.678,-11.4 -14.173,0 0,0"
                                    style="fill:#244ca1;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297094-0"/>
                        </g>
                    </g>
                    <g class="prime--panel">
                        <g
                                class="prime-on--panel"
                                transform="matrix(0.24834837,0,0,-0.25275629,121.67229,89.8099)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 0,-14.288 -11.583,-25.87 -25.87,-25.87 -14.287,0 -25.87,11.582 -25.87,25.87 0,14.287 11.583,25.87 25.87,25.87 C -11.583,25.87 0,14.287 0,0"
                                    style="fill:#00AEFF;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297098-4"/>
                        </g>
                        <g
                                class="prime-off--panel"
                                transform="matrix(0.24834837,0,0,-0.25275629,121.67229,89.8099)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 0,-14.288 -11.583,-25.87 -25.87,-25.87 -14.287,0 -25.87,11.582 -25.87,25.87 0,14.287 11.583,25.87 25.87,25.87 C -11.583,25.87 0,14.287 0,0"
                                    style="fill:#1b3978;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297098-4"/>
                        </g>
                        <g
                                id="g297100-5"
                                transform="matrix(0.24834837,0,0,-0.25275629,119.10315,89.8099)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 0,-8.574 -6.951,-15.525 -15.525,-15.525 -8.574,0 -15.525,6.951 -15.525,15.525 0,8.574 6.951,15.525 15.525,15.525 C -6.951,15.525 0,8.574 0,0"
                                    style="fill:#c1c1c6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297102-0"/>
                        </g>
                        <g
                                id="g297104-3"
                                transform="matrix(0.24834837,0,0,-0.25275629,115.24749,86.0478)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 8.466,0 15.337,-6.78 15.509,-15.205 0.002,0.108 0.016,0.212 0.016,0.32 0,8.575 -6.951,15.526 -15.525,15.526 -8.574,0 -15.525,-6.951 -15.525,-15.526 0,-0.108 0.014,-0.212 0.016,-0.32 C -15.337,-6.78 -8.466,0 0,0"
                                    style="fill:#f0f0f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297106-7"/>
                        </g>
                        <g
                                id="g297108-9"
                                transform="matrix(0.24834837,0,0,-0.25275629,115.24749,93.6329)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -8.507,0 -15.406,6.844 -15.515,15.325 -10e-4,-0.067 -0.01,-0.132 -0.01,-0.2 C -15.525,6.551 -8.574,-0.4 0,-0.4 c 8.574,0 15.525,6.951 15.525,15.525 0,0.068 -0.009,0.133 -0.01,0.2 C 15.407,6.844 8.507,0 0,0"
                                    style="fill:#929296;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297110-2"/>
                        </g>
                        <g
                                id="g297112-1"
                                transform="matrix(0.24834837,0,0,-0.25275629,115.24749,96.1336)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -14.145,0 -25.631,11.354 -25.859,25.444 -0.002,-0.142 -0.011,-0.283 -0.011,-0.425 0,-14.288 11.583,-25.87 25.87,-25.87 14.288,0 25.87,11.582 25.87,25.87 0,0.142 -0.008,0.283 -0.011,0.425 C 25.631,11.354 14.145,0 0,0"
                                    style="fill:#142957;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297114-8"/>
                        </g>
                        <g
                                id="g297116-0"
                                transform="matrix(0.24834837,0,0,-0.25275629,115.24749,83.4433)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 14.173,0 25.678,-11.4 25.861,-25.529 0.002,0.113 0.009,0.226 0.009,0.34 0,14.288 -11.582,25.87 -25.87,25.87 -14.287,0 -25.87,-11.582 -25.87,-25.87 0,-0.114 0.007,-0.227 0.009,-0.34 C -25.678,-11.4 -14.173,0 0,0"
                                    style="fill:#244ca1;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297118-1"/>
                        </g>
                    </g>
                    <g class="check-button--panel">
                        <g
                                class="check-on--panel"
                                transform="matrix(0.24834837,0,0,-0.25275629,121.67229,149.3884)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 0,-14.288 -11.583,-25.87 -25.87,-25.87 -14.287,0 -25.87,11.582 -25.87,25.87 0,14.288 11.583,25.87 25.87,25.87 C -11.583,25.87 0,14.288 0,0"
                                    style="fill:#00aeff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297122-0"/>
                        </g>
                        <g
                                class="check-off--panel"
                                transform="matrix(0.24834837,0,0,-0.25275629,121.67229,149.3884)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 0,-14.288 -11.583,-25.87 -25.87,-25.87 -14.287,0 -25.87,11.582 -25.87,25.87 0,14.288 11.583,25.87 25.87,25.87 C -11.583,25.87 0,14.288 0,0"
                                    style="fill:#1B3978;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297122-0"/>
                        </g>
                        <g
                                id="g297124-2"
                                transform="matrix(0.24834837,0,0,-0.25275629,119.10315,149.3884)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 0,-8.574 -6.951,-15.525 -15.525,-15.525 -8.574,0 -15.525,6.951 -15.525,15.525 0,8.574 6.951,15.525 15.525,15.525 C -6.951,15.525 0,8.574 0,0"
                                    style="fill:#c1c1c6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297126-5"/>
                        </g>
                        <g
                                id="g297128-5"
                                transform="matrix(0.24834837,0,0,-0.25275629,115.24749,145.6262)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 8.466,0 15.337,-6.78 15.509,-15.205 0.002,0.108 0.016,0.212 0.016,0.32 0,8.575 -6.951,15.526 -15.525,15.526 -8.574,0 -15.525,-6.951 -15.525,-15.526 0,-0.108 0.014,-0.212 0.016,-0.32 C -15.337,-6.78 -8.466,0 0,0"
                                    style="fill:#f0f0f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297130-0"/>
                        </g>
                        <g
                                id="g297132-9"
                                transform="matrix(0.24834837,0,0,-0.25275629,115.24749,153.2114)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -8.507,0 -15.406,6.844 -15.515,15.325 -10e-4,-0.067 -0.01,-0.132 -0.01,-0.2 C -15.525,6.551 -8.574,-0.4 0,-0.4 c 8.574,0 15.525,6.951 15.525,15.525 0,0.068 -0.009,0.133 -0.01,0.2 C 15.407,6.844 8.507,0 0,0"
                                    style="fill:#929296;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297134-7"/>
                        </g>
                        <g
                                id="g297136-2"
                                transform="matrix(0.24834837,0,0,-0.25275629,115.24749,155.7121)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c -14.145,0 -25.631,11.354 -25.859,25.444 -0.002,-0.142 -0.011,-0.283 -0.011,-0.425 0,-14.288 11.583,-25.87 25.87,-25.87 14.288,0 25.87,11.582 25.87,25.87 0,0.142 -0.008,0.283 -0.011,0.425 C 25.631,11.354 14.145,0 0,0"
                                    style="fill:#007bb5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297138-2"/>
                        </g>
                        <g
                                id="g297140-6"
                                transform="matrix(0.24834837,0,0,-0.25275629,115.24749,143.0217)"
                                style="stroke-width:1.40805">
                            <path
                                    d="m 0,0 c 14.173,0 25.678,-11.4 25.861,-25.529 0.002,0.113 0.009,0.226 0.009,0.34 0,14.288 -11.582,25.87 -25.87,25.87 -14.287,0 -25.87,-11.582 -25.87,-25.87 0,-0.114 0.007,-0.227 0.009,-0.34 C -25.678,-11.4 -14.173,0 0,0"
                                    style="fill:#52c8ff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                    id="path297142-1"/>
                        </g>
                    </g>
                    <text
                            transform="scale(0.81585821,1.2257032)"
                            style="font-variant:normal;font-weight:600;font-stretch:normal;font-size:3.83823px;font-family:'Proxima Nova Lt';-inkscape-font-specification:ProximaNova-Semibold;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.290359"
                            id="text297146-4"
                            x="136.53326"
                            y="64.492874">
                        <tspan
                                x="136.53326 138.86693 140.19881 141.12381 144.321"
                                y="64.492874"
                                sodipodi:role="line"
                                id="tspan297144-2"
                                style="stroke-width:0.290359">Prime
                        </tspan>
                    </text>
                    <g
                            id="g297148-7"
                            transform="matrix(0.24834837,0,0,-0.25275629,-862.2855,360.48172)"
                            style="stroke-width:1.40805">
                        <g
                                id="g297150-5"
                                clip-path="url(#clipPath297154-34-5-8)"
                                style="stroke-width:1.40805">
                            <g class="move--panel">
                                <g
                                        class="move-on--panel"
                                        transform="translate(3962.0063,951.7314)"
                                        style="stroke-width:1.40805">
                                    <path
                                            d="m 0,0 c 0,-14.288 -11.583,-25.87 -25.87,-25.87 -14.287,0 -25.87,11.582 -25.87,25.87 0,14.288 11.583,25.87 25.87,25.87 C -11.583,25.87 0,14.288 0,0"
                                            style="fill:#00aeff;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                            id="path297158-4"/>
                                </g>
                                <g
                                        class="move-off--panel"
                                        transform="translate(3962.0063,951.7314)"
                                        style="stroke-width:1.40805">
                                    <path
                                            d="m 0,0 c 0,-14.288 -11.583,-25.87 -25.87,-25.87 -14.287,0 -25.87,11.582 -25.87,25.87 0,14.288 11.583,25.87 25.87,25.87 C -11.583,25.87 0,14.288 0,0"
                                            style="fill:#1B3978;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                            id="path297158-4"/>
                                </g>
                                <g
                                        id="g297160-9"
                                        transform="translate(3951.6614,951.7314)"
                                        style="stroke-width:1.40805">
                                    <path
                                            d="m 0,0 c 0,-8.574 -6.951,-15.525 -15.525,-15.525 -8.574,0 -15.525,6.951 -15.525,15.525 0,8.574 6.951,15.525 15.525,15.525 C -6.951,15.525 0,8.574 0,0"
                                            style="fill:#c1c1c6;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                            id="path297162-5"/>
                                </g>
                                <g
                                        id="g297164-5"
                                        transform="translate(3936.1362,966.6159)"
                                        style="stroke-width:1.40805">
                                    <path
                                            d="m 0,0 c 8.466,0 15.337,-6.78 15.509,-15.205 0.002,0.108 0.016,0.212 0.016,0.32 0,8.575 -6.951,15.526 -15.525,15.526 -8.574,0 -15.525,-6.951 -15.525,-15.526 0,-0.108 0.014,-0.212 0.016,-0.32 C -15.337,-6.78 -8.466,0 0,0"
                                            style="fill:#f0f0f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                            id="path297166-9"/>
                                </g>
                                <g
                                        id="g297168-4"
                                        transform="translate(3936.1362,936.6063)"
                                        style="stroke-width:1.40805">
                                    <path
                                            d="m 0,0 c -8.507,0 -15.406,6.844 -15.515,15.325 -10e-4,-0.067 -0.01,-0.132 -0.01,-0.2 C -15.525,6.551 -8.574,-0.4 0,-0.4 c 8.574,0 15.525,6.951 15.525,15.525 0,0.068 -0.009,0.133 -0.01,0.2 C 15.407,6.844 8.507,0 0,0"
                                            style="fill:#929296;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                            id="path297170-6"/>
                                </g>
                            </g>
                        </g>
                    </g>
                    <text
                            transform="scale(0.81585821,1.2257032)"
                            style="font-variant:normal;font-weight:600;font-stretch:normal;font-size:3.83823px;font-family:'Proxima Nova Lt';-inkscape-font-specification:ProximaNova-Semibold;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.290359"
                            id="text297178-2"
                            x="136.75983"
                            y="84.991226">
                        <tspan
                                x="136.75983 139.96477 142.16402 144.09468"
                                y="84.991226"
                                sodipodi:role="line"
                                id="tspan297172-5"
                                style="stroke-width:0.290359">Move
                        </tspan>
                        <tspan
                                x="137.94199 140.52512 141.85699"
                                y="141.3979"
                                sodipodi:role="line"
                                id="tspan297174-8"
                                style="stroke-width:0.290359">Arm
                        </tspan>
                        <tspan
                                x="136.1649 138.4985 139.42358 141.23518 142.45961 144.65892"
                                y="88.553177"
                                sodipodi:role="line"
                                id="tspan297176-8"
                                style="stroke-width:0.290359">Piston
                        </tspan>
                    </text>
                    <g
                            id="g297188-4"
                            transform="matrix(0.24834837,0,0,-0.25275629,115.08835,151.7276)"
                            style="stroke-width:1.40805">
                        <path
                                d="m 0,0 -5.513,8.674 1.097,0.697 4.062,-6.391 5.179,15.129 1.23,-0.422 z"
                                style="fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path297190-6"/>
                    </g>
                    <g
                            transform="matrix(0.24834837,0,0,-0.25275629,114.94403,161.2103)"
                            style="fill:#000000;stroke-width:1.40805"
                    >
                        <path
                                d="M 0,0 C 2.532,0 4.695,-3.053 4.969,-6.83 H -4.969 C -4.695,-3.053 -2.532,0 0,0 M 6.97,-6.83 C 6.67,-1.893 3.662,2 0,2 -3.662,2 -6.67,-1.893 -6.97,-6.83 H -8.404 V -25.085 H 8.404 V -6.83 Z"
                                style="fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                class="lock--panel"
                                id="path297194-0"
                        />
                    </g>
                    <g
                            id="g297196-1"
                            transform="matrix(0.24834837,0,0,-0.25275629,106.08358,210.574)"
                            style="stroke-width:1.40805">
                        <path
                                d="m 0,0 c 0,-19.423 -27.053,-35.168 -60.426,-35.168 -33.372,0 -60.425,15.745 -60.425,35.168 0,19.423 27.053,35.168 60.425,35.168 C -27.053,35.168 0,19.423 0,0"
                                style="fill:#900f0d;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path297198-1"/>
                    </g>
                    <g
                            id="g297200-4"
                            transform="matrix(0.24834837,0,0,-0.25275629,106.07207,210.8391)"
                            style="stroke-width:1.40805">
                        <path
                                d="m 0,0 c -0.966,-18.925 -27.618,-34.096 -60.379,-34.096 -32.76,0 -59.412,15.17 -60.379,34.094 -0.022,-0.353 -0.047,-0.706 -0.047,-1.062 0,-19.417 27.054,-35.157 60.426,-35.157 33.372,0 60.425,15.74 60.425,35.157 C 0.046,-0.708 0.022,-0.354 0,0"
                                style="fill:#8a0000;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path297202-8"/>
                    </g>
                    <g
                            id="g297204-8"
                            transform="matrix(0.24834837,0,0,-0.25275629,91.07694,201.6851)"
                            style="stroke-width:1.40805">
                        <path
                                d="m 0,0 c 32.99,0 59.786,-15.384 60.397,-34.493 0.007,0.222 0.029,0.442 0.029,0.664 0,19.417 -27.053,35.157 -60.426,35.157 -33.372,0 -60.425,-15.74 -60.425,-35.157 0,-0.222 0.022,-0.442 0.029,-0.664 C -59.786,-15.384 -32.989,0 0,0"
                                style="fill:#c45858;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path297206-3"/>
                    </g>
                    <g
                            id="g297208-2"
                            transform="matrix(0.24834837,0,0,-0.25275629,87.14648,215.2087)"
                            style="stroke-width:1.40805">
                        <path
                                d="M 0,0 15.825,15.825 31.651,-0.001 C 27.428,-3.729 21.889,-5.997 15.826,-5.997 9.763,-5.997 4.223,-3.728 0,0 M -2.124,33.774 13.704,17.946 -2.121,2.121 c -3.729,4.223 -5.999,9.764 -5.999,15.828 0,6.062 2.268,11.602 5.996,15.825 m 33.779,2.123 -15.83,-15.83 -15.828,15.829 c 4.223,3.73 9.765,6 15.829,6 6.064,0 11.605,-2.27 15.829,-5.999 m -13.709,-17.951 15.83,15.83 c 3.728,-4.224 5.998,-9.764 5.998,-15.827 0,-6.065 -2.271,-11.606 -6.001,-15.83 z m -2.12,26.95 c -14.858,0 -26.946,-12.089 -26.946,-26.947 0,-14.858 12.088,-26.946 26.946,-26.946 14.859,0 26.948,12.088 26.948,26.946 0,14.858 -12.089,26.947 -26.948,26.947"
                                style="fill:#1d1d1b;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path297210-6"/>
                    </g>
                    <g
                            class="start_injection_button--panel"

                            transform="matrix(0.24834837,0,0,-0.25275629,154.42464,210.574)"
                            style="stroke-width:1.40805">
                        <path
                                d="m 0,0 c 0,-19.423 -27.053,-35.168 -60.425,-35.168 -33.372,0 -60.426,15.745 -60.426,35.168 0,19.423 27.054,35.168 60.426,35.168 C -27.053,35.168 0,19.423 0,0"
                                style="fill:#e4a600;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path297214-0"/>
                    </g>
                    <g
                            id="g297216-2"
                            transform="matrix(0.24834837,0,0,-0.25275629,154.41317,210.8391)"
                            style="stroke-width:1.40805">
                        <path
                                d="m 0,0 c -0.966,-18.925 -27.618,-34.096 -60.379,-34.096 -32.76,0 -59.412,15.17 -60.379,34.094 -0.022,-0.353 -0.047,-0.706 -0.047,-1.062 0,-19.417 27.054,-35.157 60.426,-35.157 33.372,0 60.425,15.74 60.425,35.157 C 0.046,-0.708 0.021,-0.354 0,0"
                                style="fill:#996f00;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path297218-7"/>
                    </g>
                    <g
                            id="g297220-3"
                            transform="matrix(0.24834837,0,0,-0.25275629,139.41805,201.6851)"
                            style="stroke-width:1.40805">
                        <path
                                d="m 0,0 c 32.989,0 59.786,-15.384 60.397,-34.493 0.007,0.222 0.029,0.442 0.029,0.664 0,19.417 -27.054,35.157 -60.426,35.157 -33.372,0 -60.425,-15.74 -60.425,-35.157 0,-0.222 0.022,-0.442 0.029,-0.664 C -59.786,-15.384 -32.989,0 0,0"
                                style="fill:#ffd054;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path297222-0"/>
                    </g>
                    <g
                            id="g297224-0"
                            transform="matrix(0.24834837,0,0,-0.25275629,139.41805,203.8608)"
                            style="stroke-width:1.40805">
                        <path
                                d="m 0,0 c -14.858,0 -26.947,-12.089 -26.947,-26.947 0,-14.859 12.089,-26.947 26.947,-26.947 14.859,0 26.947,12.088 26.947,26.947 C 26.947,-12.089 14.859,0 0,0 m 0,-3 c 13.205,0 23.947,-10.743 23.947,-23.947 0,-13.204 -10.742,-23.947 -23.947,-23.947 -13.204,0 -23.947,10.743 -23.947,23.947 C -23.947,-13.743 -13.204,-3 0,-3"
                                style="fill:#1d1d1b;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path297226-3"/>
                    </g>
                    <g
                            id="g297228-0"
                            transform="matrix(0.24834837,0,0,-0.25275629,136.08245,208.5639)"
                            style="stroke-width:1.40805">
                        <path
                                d="m 0,0 v -16.808 l 15.681,8.404 z"
                                style="fill:#1d1d1b;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.40805"
                                id="path297230-1"/>
                    </g>
                    <path
                            d="m 142.0271,212.9359 h -0.5285 v -4.4957 h 0.5285 z"
                            style="fill:#1d1d1b;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352776"
                            id="path297232-1"/>
                    <path
                            d="m 142.85136,212.9359 h -0.52848 v -4.4957 h 0.52848 z"
                            style="fill:#1d1d1b;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.352776"
                            id="path297234-5"/>
                </g>
                <rect :class="$store.getters['sequences_store/getActiveStep'].state.value === 'head_zoom/1' && 'selected-area'"
                      style="x: 3;
                        y: 7;
                        width: 89px;
                        height: 31px;
                        fill: transparent;
                        stroke: red;
                        stroke-dasharray: 4 2;"
                      @click="$store.getters['sequences_store/getActiveStep'].state.value = 'head_zoom/1'"
                >

                </rect>
                <rect style="x: 3;
                        y: 45;
                        width: 89px;
                        height: 25px;
                        fill: transparent;
                        stroke: red;
                        stroke-dasharray: 4 2;"
                      @click="$store.getters['sequences_store/getActiveStep'].state.value = 'head_zoom/2'"
                      :id="$store.getters['sequences_store/getActiveStep'].state.value === 'head_zoom/2' && 'selected-fill-buttons'"
                >

                </rect>
                <g class="rect-3"
                   :class="$store.getters['sequences_store/getActiveStep'].state.value === 'head_zoom/3' && 'selected-area'"
                   @click="$store.getters['sequences_store/getActiveStep'].state.value = 'head_zoom/3'">
                    <line x1="3" y1="260" x2="3" y2="380" stroke="#FF0000"
                          stroke-dasharray="4 2"/>
                    <line x1="3" y1="380" x2="36" y2="380" stroke="#FF0000" stroke-dasharray="4 2"/>
                    <line x1="36" y1="380" x2="36" y2="315" stroke="#FF0000" stroke-dasharray="4 2"/>
                    <line x1="36" y1="315" x2="60" y2="315" stroke="#FF0000" stroke-dasharray="4 2"/>
                    <line x1="60" y1="315" x2="60" y2="380" stroke="#FF0000" stroke-dasharray="4 2"/>
                    <line x1="60" y1="380" x2="93" y2="380" stroke="#FF0000" stroke-dasharray="4 2"/>
                    <line x1="93" y1="380" x2="93" y2="260" stroke="#FF0000" stroke-dasharray="4 2"/>
                    <line x1="93" y1="260" x2="3" y2="260" stroke="#FF0000" stroke-dasharray="4 2"/>
                    <rect style="fill: transparent; x: 3; y: 260; height: 120px; width: 33px;"></rect>
                    <rect style="fill: transparent; x: 59; y: 260; height: 120px; width: 33px;"></rect>
                    <rect style="fill: transparent; x: 36; y: 260; height: 55px; width: 23px;"></rect>
                </g>
                <rect style="x: 38;
                        y: 130;
                        width: 20px;
                        height: 64px;
                        fill: transparent;
                        stroke: red;
                        stroke-dasharray: 4 2;"
                      @click="$store.getters['sequences_store/getActiveStep'].state.value = 'head_zoom/4'"
                      :class="$store.getters['sequences_store/getActiveStep'].state.value === 'head_zoom/4' && 'selected-area'"
                >

                </rect>
                <rect style="x: 3;
                        y: 196;
                        width: 89px;
                        height: 28px;
                        fill: transparent;
                        stroke: red;
                        stroke-dasharray: 4 2;"
                      @click="$store.getters['sequences_store/getActiveStep'].state.value = 'head_zoom/5'"
                      :class="$store.getters['sequences_store/getActiveStep'].state.value === 'head_zoom/5' && 'selected-area'"
                      :id="$store.getters['sequences_store/getActiveStep'].state.value === 'head_zoom/5' && 'selected-footer'"
                >

                </rect>
            </svg>
        </div>
        <FluidsAmountScreen v-if="$store.getters['sequences_store/getActiveStep'].state.value === 'head_zoom/1'"/>
        <AutoLoadMode v-if="$store.getters['sequences_store/getActiveStep'].state.value === 'head_zoom/2'"/>
        <ManualLoadMode v-if="$store.getters['sequences_store/getActiveStep'].state.value === 'head_zoom/3'"/>
        <CheckAirAndLockArea v-if="$store.getters['sequences_store/getActiveStep'].state.value === 'head_zoom/4'"/>
        <ControlButtons v-if="$store.getters['sequences_store/getActiveStep'].state.value === 'head_zoom/5'"/>
        <div class="comeback-button" @click="$store.getters['sequences_store/getActiveStep'].state.value = ''">
            <img src="@/img/injector/icons/loupe-close.svg" alt="">
            Вернуться
        </div>
    </div>
</template>

<script>
    export default {
        name: "EnlargedForEducation",
        components: {
            AutoLoadMode: () => import('@/components/EducationMode/InjectorEnlargedPanelWrappers/AutoLoadMode'),
            CheckAirAndLockArea: () => import('@/components/EducationMode/InjectorEnlargedPanelWrappers/CheckAirAndLockArea'),
            ControlButtons: () => import('@/components/EducationMode/InjectorEnlargedPanelWrappers/ControlButtons'),
            FluidsAmountScreen: () => import('@/components/EducationMode/InjectorEnlargedPanelWrappers/FluidsAmountScreen'),
            ManualLoadMode: () => import('@/components/EducationMode/InjectorEnlargedPanelWrappers/ManualLoadMode'),
        },
    }
</script>

<style lang="scss" scoped>
    rect, .rect-3 {
        cursor: pointer;
        transition: fill ease 0.5s;
    }

    .rect-3 {
        transform: translate(0, -82%);
    }

    .selected-area {
        fill: rgba(#A19732, 0.7) !important;

        * {
            fill: rgba(#44E072, 0.5) !important;
        }
    }
    #selected-fill-buttons {
        fill: rgba(#C9D669, 0.5) !important;
    }
    #selected-manual-loading {
        fill: rgba(#44E072, 0.5) !important;
    }
    #selected-footer {
        fill: rgba(#4A86C7, 0.5) !important;
    }
    .comeback-button {
        border: 2px solid orange;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 5px 40px;
        position: absolute;
        bottom: 5%;
        left: 55%;
        color: orange;
        font-weight: 600;
        gap: 10px;
        cursor: pointer;

        img {
            width: 30px;
            height: auto;
        }
    }
</style>